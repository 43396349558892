import axios from 'axios';
import { useEffect, useState } from 'react';
import useApiUrl from './useApiUrlHook';

export default function useMenu() {
    const [menu, setMenu] = useState(null);
    const apiUrl = useApiUrl();

    useEffect(() => {
        const controller = new AbortController();

        async function getMenu() {
            await axios
                .get(`${apiUrl}/page/menu`, {
                    signal: controller.signal,
                })
                .then((response) => {
                    setMenu(response.data);
                })
                .catch((err) => {
                    if (err.name === 'AbortError' || err.name === 'CanceledError') {
                        console.log('successfully aborted');
                    } else {
                        throw new Error(err);
                    }
                });
        }
        if (menu === null && apiUrl !== null) {
            getMenu();
        }

        return () => {
            controller.abort();
        };
    }, [menu, apiUrl]);

    return menu;
}
