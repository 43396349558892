import { lazy, Suspense } from 'react';
import Loader from '../components/blocks/Loader';
import Navigation from '../components/Navigation';
import NewsOverview from '../components/news/NewsOverview';
import usePage from '../framework/hooks/usePageHook';

const Brochure = lazy(() => import('../components/blocks/Brochure'));
const USPSection = lazy(() => import('../components/blocks/USPSection'));
const Footer = lazy(() => import('../components/Footer'));

export default function NewsPage({ page }) {
    return (
        <>
            <Navigation />
            <NewsOverview />
            <Suspense fallback={<Loader />}>
                <Brochure />
                <USPSection />
                <Footer />
            </Suspense>
        </>
    );
}
