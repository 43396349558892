import { marked } from 'marked';

export default function ApolloMarkdown({ inline, children }) {
    if (children === null || children === undefined || typeof children !== 'string') return null;

    return (
        <slot
            dangerouslySetInnerHTML={{
                __html: inline ? marked.parseInline(children) : marked.parse(children),
            }}
        />
    );
}
