import { lazy, Suspense } from 'react';
import Loader from '../components/blocks/Loader';
import TeamOverview from '../components/blocks/TeamOverview';
import Navigation from '../components/Navigation';
import usePage from '../framework/hooks/usePageHook';

const ExpertiseHome = lazy(() => import('../components/blocks/ExpertiseHome'));
const LatestNews = lazy(() => import('../components/news/LatestNews'));
const USPSection = lazy(() => import('../components/blocks/USPSection'));
const Footer = lazy(() => import('../components/Footer'));

export default function TeamPage({ page }) {
    const pageObj = usePage(page.id);

    return (
        <>
            <Navigation />
            <TeamOverview page={pageObj} />
            <Suspense fallback={<Loader/>}>
                <ExpertiseHome />
                <LatestNews />
                <USPSection />
                <Footer />
            </Suspense>
        </>
    );
}
