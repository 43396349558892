// @ts-nocheck
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ApolloMarkdown from '../../framework/components/ApolloMarkdown';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import BlockReveal from '../interaction/BlockReveal';
import './scss/productCard.scss';

export default function ProductCard({ product, delay = '1s' }) {
    const [item, setItem] = useState(product);
    const apiUrl = useApiUrl();

    useEffect(() => {
        setItem(product);
    }, [product]);

    return (
        <BlockReveal delay={delay} offsetDirection="bottom" offset="45%">
            <Link
                to={`/producten/${item.id}/${
                    item.url_titel ?? encodeURIComponent(item.title.toLowerCase().replace(/ /g, '-').replace(/\*/g, ''))
                }`}
                className="productsCard"
            >
                <div className="image">
                    <div className="image-decoration--dash-top image-decoration--dash-blue" />
                    <div className="image-decoration--dash-right image-decoration--dash-blue" />

                    <div className="text">
                        <h2>
                            <ApolloMarkdown inline>{item.title}</ApolloMarkdown>
                        </h2>
                    </div>
                </div>
                {apiUrl !== null && item ? (
                    <img
                        src={`${apiUrl}/img/ratio/product_category/410/310/100/${item.image}`}
                        alt=""
                        className="background"
                    />
                ) : null}
            </Link>
        </BlockReveal>
    );
}
