import { lazy, Suspense } from 'react';
import ContactSection from '../components/blocks/ContactSection';
import Loader from '../components/blocks/Loader';
import Navigation from '../components/Navigation';
import usePage from '../framework/hooks/usePageHook';

const USPSection = lazy(() => import('../components/blocks/USPSection'));
const Footer = lazy(() => import('../components/Footer'));

export default function ContactPage({ page }) {
    const pageObj = usePage(page.id);

    return (
        <>
            <Navigation />
            <ContactSection page={pageObj} />
            <Suspense fallback={<Loader/>}>
                <USPSection />
                <Footer />
            </Suspense>
        </>
    );
}
