import { useState } from 'react';
import { Waypoint } from 'react-waypoint';

export default function BlockReveal({
    children,
    offsetDirection = 'top',
    offset = '50%',
    speed = '.45s',
    delay = '.5s',
}) {
    const [isInView, setIsInView] = useState(false);
    const handleInView = () => setIsInView(true);

    const inner = (
        <div
            className="blockReveal"
            style={{
                transform: `translateY(${isInView ? '0' : '50px'})`,
                opacity: isInView ? '1' : '0',
                pointerEvents: isInView ? 'all' : 'none',
                transitionDuration: speed,
                transitionDelay: delay,
            }}
        >
            {children}
        </div>
    );

    if (offsetDirection === 'top') {
        return (
            <Waypoint topOffset={offset} onEnter={handleInView}>
                {inner}
            </Waypoint>
        );
    }
    return (
        <Waypoint bottomOffset={offset} onEnter={handleInView}>
            {inner}
        </Waypoint>
    );
}
