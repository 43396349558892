export default function ContactLocation({ value }) {
    return (
        <article className="location">
            <div className="row">
                <div className="col col--12">
                    <h2>{value.name}</h2>
                </div>
                <div className="col col--6">
                    <div className="val">{value.street}</div>
                    <div className="val">{value.postcode}</div>
                    <div className="val">{value.country}</div>
                </div>
                <div className="col col--6">
                    <div className="val">
                        <img src="/assets/svg/phoneGreen.svg" alt="" />
                        {value.tel}
                    </div>
                    <div className="val">
                        <img src="/assets/svg/mail.svg" alt="" />
                        {value.email}
                    </div>
                </div>
            </div>
        </article>
    );
}
