import { Link } from 'react-router-dom';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import './scss/newsOverviewCard.scss';

export default function NewsOverviewCard({ size, item }) {
    const apiUrl = useApiUrl();

    return (
        <div className={`col ${size === 'large' ? 'col--12' : 'col--6'}`}>
            <Link
                to={`/nieuws/${item.id}/${
                    item.url_titel !== null && item.url_titel !== ''
                        ? item.url_titel
                        : encodeURIComponent(item.title.toLowerCase().replace(/ /g, '-').replace(/\*/g, ''))
                }`}
                className={`newsOverviewCard ${
                    size === 'large' ? 'newsOverviewCard--large' : 'newsOverviewCard--small'
                }`}
            >
                <div className="background">
                    <div className="overlay" />
                    <img
                        src={`${apiUrl}/img/ratio/news/${size === 'large' ? '655/490' : '318/238'}/100/${item.image}`}
                        alt=""
                    />
                </div>
                <div className="details">
                    <div className="title">{item?.title ?? ''}</div>
                    <div className="date">
                        {new Date(item?.publicatie_op).toLocaleDateString('nl-nl', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        })}
                    </div>
                </div>
            </Link>
        </div>
    );
}
