import { Link } from 'react-router-dom';
import './scss/button.scss';

export default function button({ to, children }) {
    return (
        <Link className="btn" to={to}>
            {children}
        </Link>
    );
}
