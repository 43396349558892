import './scss/preloader.scss';

export default function Preloader({ status }) {
    return (
        <section className={`preloader ${status}`}>
            <div className="preloader-container">
                <span className="animated-preloader" />
            </div>
        </section>
    );
}
