import axios from 'axios';
import { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import ApolloMarkdown from '../../framework/components/ApolloMarkdown';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import Loader from './Loader';
import TeamCard from './TeamCard';

import './scss/teamPage.scss';

export default function TeamOverview({ page }) {
    const [isInView, setIsInView] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [team, setTeam] = useState(null);

    const apiUrl = useApiUrl();

    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    useEffect(() => {
        async function getTeam() {
            if (apiUrl === null) return;

            await axios
                .get(`${apiUrl}/team`)
                .then((response) => {
                    setTeam(response.data);
                    setIsLoaded(true);
                })
                .catch(() => {
                    setIsLoaded(false);
                });
        }

        if (team === null) getTeam();
    }, [team, apiUrl]);

    if (isLoaded) {
        return (
            <section className="teamPage">
                <Waypoint topOffset="50%" onEnter={handleInView} onLeave={handleOutOfView}>
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg--6 col--3">
                                <div className="teamCard teamCard--text">
                                    <div className={`title ${isInView ? 'active' : ''}`}>
                                        <h1>
                                            <ApolloMarkdown inline>{page?.paginatitel}</ApolloMarkdown>
                                        </h1>
                                    </div>
                                    <ApolloMarkdown inline={false}>{page?.inhoud}</ApolloMarkdown>
                                </div>
                                {team
                                    .filter((_value, index) => index % 4 === 3)
                                    .map((value, index) => (
                                        <TeamCard key={index} member={value} />
                                    ))}
                            </div>
                            <div className="col col-lg--6 col--3">
                                {team
                                    .filter((_value, index) => index % 4 === 0)
                                    .map((value, index) => (
                                        <TeamCard key={index} member={value} />
                                    ))}
                            </div>
                            <div className="col col-lg--6 col--3">
                                {team
                                    .filter((_value, index) => index % 4 === 1)
                                    .map((value, index) => (
                                        <TeamCard key={index} member={value} />
                                    ))}
                            </div>
                            <div className="col col-lg--6 col--3">
                                {team
                                    .filter((_value, index) => index % 4 === 2)
                                    .map((value, index) => (
                                        <TeamCard key={index} member={value} />
                                    ))}
                            </div>
                        </div>
                    </div>
                </Waypoint>
            </section>
        );
    }

    return <Loader />;
}
