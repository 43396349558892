import { lazy, Suspense } from 'react';
import HeaderHome from '../components/headers/HeaderHome';

// import Preloader from '../components/Preloader';
import Navigation from '../components/Navigation';
import usePage from '../framework/hooks/usePageHook';
import Preloader from '../components/Preloader';

// const HeaderHome = lazy(() => import('../components/headers/HeaderHome'));
const ProductsHome = lazy(() => import('../components/products/ProductsHome'));
const ExpertiseHome = lazy(() => import('../components/blocks/ExpertiseHome'));
const TeamHome = lazy(() => import('../components/blocks/TeamHome'));
const LatestNews = lazy(() => import('../components/news/LatestNews'));
const USPSection = lazy(() => import('../components/blocks/USPSection'));
const Footer = lazy(() => import('../components/Footer'));

export default function HomePage({ page }) {
    const pageObj = usePage(page.id);

    // <Suspense fallback={<Preloader status={!pageObj ? 'active' : 'out'} />}>
    return (
        <>
            <Navigation />
            <HeaderHome page={pageObj} />
            <Suspense fallback={<h1>loading</h1>}>
                <ProductsHome />
                <ExpertiseHome />
                <TeamHome />
                <LatestNews />
                <USPSection />
                <Footer />
            </Suspense>
        </>
    );
}
