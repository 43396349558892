import { useEffect, useRef, useState } from 'react';
import useMediaSize from '../hooks/useMediaSize';
import usePageSize from '../hooks/usePageSizeHook';

export default function ApolloParallax({
    className,
    speed,
    offset = '0px',
    children,
    constraint = null,
    enabled = true,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    log = false,
}) {
    const [offsetY, setOffsetY] = useState(0);
    const [elementOffsetY, setElementOffsetY] = useState(0);
    const [minY, setMinY] = useState(null);
    const [maxY, setMaxY] = useState(null);
    const [constraintHeight, setConstraintHeight] = useState(null);
    const [scrollDirection, setScrollDirection] = useState(null);
    const [isEnabled, setIsEnabled] = useState(enabled);

    const [translateY, setTranslateY] = useState(0);

    const { y: pageHeight } = usePageSize();

    const size = useMediaSize();
    const elementRef = useRef();

    useEffect(() => {
        setIsEnabled(size.width >= size.breakpoint.xl);
    }, [size]);

    const getElementCoords = (element) => {
        const box = element.getBoundingClientRect();

        const { body } = document;
        const docEl = document.documentElement;

        const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        const clientTop = docEl.clientTop || body.clientTop || 0;

        const top = box.top + scrollTop - clientTop;
        const bottom = top + box.height;

        return { top: Math.round(top), bottom: Math.round(bottom), height: box.height };
    };

    const handleScrollEvent = () => {
        setOffsetY(window.scrollY - elementOffsetY);
    };

    const handleParallaxConstraint = async () => {
        const constraintElement = constraint.current;
        if (constraintElement !== null) {
            // @ts-ignore
            const { top, bottom, height } = getElementCoords(constraintElement);
            setMinY(top);
            setMaxY(bottom);
            setConstraintHeight(height);
        }
    };

    const handleResizeEvent = async () => {
        const element = elementRef.current;

        if (constraint !== null) handleParallaxConstraint();

        if (element !== undefined && element !== null) {
            // @ts-ignore
            const { top } = element.getBoundingClientRect();
            setElementOffsetY(top + window.scrollY);
        }
    };

    const handleScrollDirection = (e) => {
        setScrollDirection(e.wheelDelta < 0 ? 'down' : 'up');
    };

    useEffect(() => {
        window.addEventListener('mousewheel', handleScrollDirection);
        window.addEventListener('resize', handleResizeEvent);
        window.addEventListener('scroll', handleScrollEvent);

        handleResizeEvent();
        handleScrollEvent();

        return () => {
            window.removeEventListener('mousewheel', handleScrollDirection);
            window.removeEventListener('resize', handleResizeEvent);
            window.removeEventListener('scroll', handleScrollEvent);
        };
    }, [elementOffsetY]);

    useEffect(() => {
        setTimeout(handleResizeEvent, 500);
    }, [pageHeight]);

    useEffect(() => {
        let transformValue = offsetY * (speed ?? 1);

        const element = elementRef.current;
        if (element !== undefined && constraint !== null) {
            const { top, bottom, height } = getElementCoords(element);
            if (scrollDirection === 'down') {
                if (bottom >= maxY) {
                    const desiredTop = minY + constraintHeight - height;
                    transformValue = Math.round(desiredTop - (top - translateY));
                } else if (top < minY) {
                    const desiredTop = minY;
                    transformValue = Math.round(desiredTop - (top - translateY));
                }
            } else if (scrollDirection === 'up') {
                if (bottom > maxY) {
                    const desiredTop = minY + constraintHeight - height;
                    transformValue = Math.round(desiredTop - (top - translateY));
                } else if (top <= minY) {
                    const desiredTop = minY;
                    transformValue = Math.round(desiredTop - (top - translateY));
                }
            }
        }
        setTranslateY(transformValue);
    }, [offsetY, speed]);

    return (
        <div
            ref={elementRef}
            className={className ?? ''}
            style={{ transform: isEnabled ? `translateY(calc(${translateY}px + ${offset}))` : 'none' }}
        >
            {children}
        </div>
    );
}
