import useApiUrl from '../../framework/hooks/useApiUrlHook';

import './scss/teamCard.scss';

export default function TeamCard({ member }) {
    const apiUrl = useApiUrl();

    return (
        <div className="teamCard">
            <div className="background">
                <div className="overlay" />
                <img src={`${apiUrl}/img/ratio/team/775/500/100/${member.image}`} alt="" />
            </div>
            <div className="details">
                <div className="name">{member.naam}</div>
                <div className="function">{member.functie}</div>
                <div className="email">{member.email}</div>
            </div>
        </div>
    );
}
