import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useMediaSize from '../framework/hooks/useMediaSize';
import useMenu from '../framework/hooks/useMenuHook';
import Button from './interaction/button';

import './scss/nav.scss';
import 'hamburgers/dist/hamburgers.css';

export default function Navigation() {
    const menu = useMenu();
    const size = useMediaSize();

    const [isDesktop, setIsDesktop] = useState(true);
    const [menuActive, setMenuActive] = useState(false);

    const handleHamburgerClick = () => {
        setMenuActive(!menuActive);
    };

    useEffect(() => {
        setIsDesktop(size.width >= size.breakpoint.xl);
    }, [size]);

    if (isDesktop) {
        return (
            <nav className="desktop">
                <div className="container">
                    <div className="row">
                        <div className="col col--3 logoCol">
                            <Link to="/">
                                <img width={200} height={80} src="/assets/svg/logoDiap.svg" alt="" />
                            </Link>
                        </div>
                        <div className="col col--9 navCol">
                            <ul className="primary">
                                {menu?.map((e, i) => (
                                    <li key={i}>
                                        <Link to={`/${e.url_titel}`}>{e.titel}</Link>

                                        {e.sub === null ? (
                                            ''
                                        ) : (
                                            <ul className="sub">
                                                {e.sub.map((e2, i2) => (
                                                    <li key={i2}>
                                                        <Link to={`/${e2.url_titel}`}>{e2.titel}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                            <ul className="secondary">
                                <li>
                                    <Button to="/contact">Contact</Button>
                                </li>
                                <li>
                                    <Link to="tel:" className="btn--icon">
                                        <div className="icon">
                                            <img width={30} height={30} src="/assets/svg/phone.svg" alt="" />
                                        </div>
                                        +31 (0) 541 - 74 60 16
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
    return (
        <>
            <div className="logoBar">
                <div className="container">
                    <div className="row">
                        <div className="col col--4 logoCol">
                            <Link to="/">
                                <img width={90} height={38} src="/assets/svg/logoDiap.svg" alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <nav className={`appBar ${menuActive ? 'is-active' : ''}`}>
                <div className="wrap">
                    <div className="cutOut" />
                    <div className="buttons buttons--left">
                        <div className="menuButton">
                            <Link to="/">
                                <img src="/assets/svg/icon_home.svg" alt="" />
                            </Link>
                        </div>
                        <div className="menuButton">
                            <Link to="/producten">
                                <img src="/assets/svg/icon_products.svg" alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className="fab">
                        <button
                            onClick={handleHamburgerClick}
                            className={`hamburger hamburger--elastic ${menuActive ? 'is-active' : ''}`}
                            type="button"
                        >
                            <span className="hamburger-box">
                                <span className="hamburger-inner" />
                            </span>
                        </button>
                    </div>
                    <div className="buttons buttons--right">
                        <div className="menuButton">
                            <Link to="/team">
                                <img src="/assets/svg/icon_team.svg" alt="" />
                            </Link>
                        </div>
                        <div className="menuButton">
                            <Link to="/contact">
                                <img src="/assets/svg/icon_contact.svg" alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="menu">
                    <div className="inner">
                        <ul>
                            {menu?.map((e, i) => (
                                <li key={i}>
                                    <Link to={`/${e.url_titel}`}>{e.titel}</Link>

                                    {e.sub === null ? (
                                        ''
                                    ) : (
                                        <ul className="sub">
                                            {e.sub.map((e2, i2) => (
                                                <li key={i2}>
                                                    <Link to={`/${e2.url_titel}`}>{e2.titel}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                            <li>
                                <Link to="/contact">Contact</Link>
                            </li>
                            <li>
                                <Link to="tel:" className="btn--icon">
                                    <div className="icon">
                                        <img width={27} height={23} src="/assets/svg/phone.svg" alt="" />
                                    </div>
                                    +31 (0) 541 - 74 60 16
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}
