import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';

import { Helmet } from 'react-helmet';
import HomePage from '../views/HomePage';
import ContentPage from '../views/ContentPage';
import useApiUrl from './hooks/useApiUrlHook';
import ProductsPage from '../views/ProductsPage';
import ProductDetailPage from '../views/ProductDetailPage';
import TeamPage from '../views/TeamPage';
import NewsDetailPage from '../views/NewsDetailPage';
import NewsPage from '../views/NewsPage';
import ContactPage from '../views/ContactPage';
import Preloader from '../components/Preloader';

/**
 * Catchall for unmatched routes reroutes to correct view.
 */
export default function ResolvePage({ detailPage = false }) {
    const { path, id, urlTitle } = useParams();
    const apiUrl = useApiUrl();

    const location = useLocation();

    const [page, setPage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        if (apiUrl === null) return;

        axios
            .post(`${apiUrl}/page`, {
                path,
                id: id ?? null,
                urlTitle: urlTitle ?? null,
            })
            .then((response) => {
                setPage(response.data);
            })
            .catch((error) => {
                setErrorMessage(error);
            });
    }, [path, apiUrl]);

    if (errorMessage !== null) return <div>Foutmelding page {errorMessage}</div>;

    if (page === null) return <Preloader status="active" />;

    let pageElement;

    switch (page.script) {
        case 'home':
            pageElement = <HomePage page={page} />;
            break;
        case 'producten':
            if (detailPage) {
                pageElement = <ProductDetailPage page={page} id={id} />;
                break;
            }
            pageElement = <ProductsPage page={page} />;
            break;
        case 'team':
            pageElement = <TeamPage page={page} />;
            break;
        case 'nieuws':
            if (detailPage) {
                pageElement = <NewsDetailPage page={page} id={id} />;
                break;
            }
            pageElement = <NewsPage page={page} />;
            break;
        case 'contact':
            pageElement = <ContactPage page={page} />;
            break;
        default:
            pageElement = <ContentPage page={page} />;
            break;
    }

    return (
        <>
            <Helmet>
                <title>
                    {page.titletag !== '' ? page.titletag : page.titel} - {process.env.MIX_REACT_APP_APP_NAME}
                </title>
                <meta
                    name="name"
                    content={`${page.titletag !== '' ? page.titletag : page.titel} - ${
                        process.env.MIX_REACT_APP_APP_NAME
                    }`}
                />
                {page.image !== null && page.image !== '' ? (
                    <meta name="image" content={`${apiUrl}/img/exactg/website/1920/1080/100/${page.image}`} />
                ) : null}
                <meta name="description" content={page.description} />
                <meta name="keywords" content={page.keywords} />
            </Helmet>
            {pageElement}
        </>
    );
}
