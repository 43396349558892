import axios from 'axios';
import { useEffect, useState } from 'react';
import useApiUrl from './useApiUrlHook';

export default function usePage(pageId) {
    const [page, setPage] = useState(null);
    const apiUrl = useApiUrl();

    useEffect(() => {
        async function getPage() {
            if (apiUrl === null) return;

            await axios
                .get(`${apiUrl}/page/${pageId}`)
                .then((response) => {
                    setPage(response.data);
                })
                .catch((error) => {
                    throw new Error(error);
                });
        }

        getPage();
    }, [pageId, apiUrl]);

    return page;
}
