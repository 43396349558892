import { CookieConsentProvider } from '@use-cookie-consent/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ApolloCookieConsent from './components/ApolloCookieConsent';
import ResolvePage from './ResolvePage';

export default function Apollo() {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.MIX_REACT_APP_RECAPTCHA}>
            <BrowserRouter>
                <CookieConsentProvider>
                    <Helmet>
                        <title>{process.env.MIX_REACT_APP_APP_NAME}</title>
                    </Helmet>
                    <Routes>
                        <Route path="/">
                            <Route index element={<ResolvePage />} />

                            {/* Project specific routes */}

                            {/* Framework routes */}
                            <Route path=":path">
                                <Route path=":id" element={<ResolvePage />} />
                                <Route path=":id/:urlTitle" element={<ResolvePage detailPage />} />
                                <Route index element={<ResolvePage />} />
                            </Route>
                        </Route>
                    </Routes>
                    <ApolloCookieConsent />
                </CookieConsentProvider>
            </BrowserRouter>
        </GoogleReCaptchaProvider>
    );
}
