import './scss/apolloPrerender.scss';

export default function ApolloPrerender({
    lines = 1,
    fontSize = '1em',
    lineHeight = '.2em',
    width = '100%',
    children = null,
    rtl = false,
    isPlainText = false,
    test = false,
}) {
    const renderLines = () => {
        const linesObj = [];

        for (let i = 0; i < lines; i += 1) {
            linesObj.push(
                <div
                    key={i}
                    className="preloaderLine"
                    style={{
                        height: fontSize,
                        marginBottom: lineHeight,
                        width: lines > 1 && i === lines - 1 ? '75%' : width,
                        marginLeft: rtl ? 'auto' : '0',
                        marginRight: rtl ? '0' : 'auto',
                    }}
                />,
            );
        }

        return linesObj;
    };

    if (isPlainText) {
        if (children === null || children === undefined || children === '') {
            return renderLines();
        }
    } else if (
        typeof children !== 'object' ||
        children === null ||
        children === undefined ||
        children?.props?.children === undefined
    ) {
        return renderLines();
    }

    if (test) {
        return renderLines();
    }

    return children;
}
