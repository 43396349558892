import { useLayoutEffect, useState } from 'react';

export default function useMediaSize() {
    const [size, setSize] = useState({
        width: 0,
        breakpoint: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1400 },
    });

    useLayoutEffect(() => {
        const { body } = document;
        const html = document.documentElement;

        function updateSize() {
            const width = Math.max(body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);

            setSize({ ...size, width });
        }

        window.addEventListener('resize', updateSize);

        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
}
