import { lazy, Suspense } from 'react';
import Loader from '../components/blocks/Loader';
import Navigation from '../components/Navigation';
import ProductDetail from '../components/products/ProductDetail';
import usePage from '../framework/hooks/usePageHook';

const Brochure = lazy(() => import('../components/blocks/Brochure'));
const USPSection = lazy(() => import('../components/blocks/USPSection'));
const Footer = lazy(() => import('../components/Footer'));

export default function ProductDetailPage({ page, id }) {
    return (
        <>
            <Navigation />
            <ProductDetail id={id} />
            <Suspense fallback={<Loader />}>
                {/* <Brochure /> */}
                <USPSection />
                <Footer />
            </Suspense>
        </>
    );
}
