import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import ApolloMarkdown from '../../framework/components/ApolloMarkdown';
import ApolloParallax from '../../framework/components/ApolloParallax';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import useMediaSize from '../../framework/hooks/useMediaSize';
import Loader from '../blocks/Loader';
import ProductCard from './ProductCard';

import './scss/productHome.scss';

export default function ProductsHome() {
    const [isInView, setIsInView] = useState(false);
    const [categories, setCategories] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isDesktop, setIsDesktop] = useState(true);
    const apiUrl = useApiUrl();
    const parallaxConstraint = useRef();
    const size = useMediaSize();

    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    useEffect(() => {
        setIsDesktop(size.width >= size.breakpoint.xl);
    }, [size]);

    useEffect(() => {
        async function getCategories() {
            if (apiUrl === null) return;

            await axios
                .get(`${apiUrl}/products/overview`)
                .then((response) => {
                    setCategories(response.data);
                    setIsLoaded(true);
                })
                .catch(() => {
                    setIsLoaded(false);
                });
        }

        if (categories === null) getCategories();
    }, [categories, apiUrl]);

    return (
        <section className="productsHome" style={{ marginBottom: isDesktop ? '400px' : 0 }}>
            <Waypoint bottomOffset="50%" onEnter={handleInView} onLeave={handleOutOfView}>
                <div className="container">
                    <div className="parallaxConstraint" ref={parallaxConstraint} />
                    <div className="row">
                        <div className="col col--12">
                            <div className={`title ${isInView ? 'active' : ''}`}>
                                <h1>
                                    <ApolloMarkdown inline>Ons **assortiment**</ApolloMarkdown>
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div className="row productsRow">
                        <ApolloParallax
                            className="col col-lg--6 col--4"
                            speed={0}
                            offset="50px"
                            enabled={isDesktop}
                            constraint={parallaxConstraint}
                        >
                            {isLoaded
                                ? categories
                                      .filter((_value, index) => index % 3 === 0)
                                      .map((value, index) => <ProductCard key={index} product={value} delay="100ms" />)
                                : 'Loading...'}
                        </ApolloParallax>
                        <ApolloParallax
                            className="col col-lg--6 col--4"
                            speed={0.1}
                            offset="50px"
                            enabled={isDesktop}
                            constraint={parallaxConstraint}
                        >
                            {isLoaded
                                ? categories
                                      .filter((_value, index) => index % 3 === 1)
                                      .map((value, index) => <ProductCard key={index} product={value} delay="250ms" />)
                                : 'Loading...'}
                        </ApolloParallax>
                        <ApolloParallax
                            className="col col-lg--6 col--4"
                            speed={0.2}
                            offset="50px"
                            enabled={isDesktop}
                            constraint={parallaxConstraint}
                        >
                            {isLoaded
                                ? categories
                                      .filter((_value, index) => index % 3 === 2)
                                      .map((value, index) => <ProductCard key={index} product={value} delay="500ms" />)
                                : 'Loading...'}
                        </ApolloParallax>
                    </div>
                </div>
            </Waypoint>
        </section>
    );
}
