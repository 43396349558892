// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';

import { render } from 'react-dom';
import Apollo from './framework/Apollo';

import '../sass/theme/main.scss';

// eslint-disable-next-line react/jsx-filename-extension
render(<Apollo />, document.getElementById('app'));
