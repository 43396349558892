/* eslint-disable react/button-has-type */
import { useCookieConsentContext } from '@use-cookie-consent/react';
import { useState } from 'react';
import Toggle from 'react-toggle';

import './scss/apolloCookieConsent.scss';

export default function ApolloCookieConsent() {
    const { consent, acceptAllCookies, declineAllCookies, acceptCookies } = useCookieConsentContext();

    const [presentationCookies, setPresentationCookies] = useState(null);
    const [targetingCookies, setTargetingCookies] = useState(null);
    const [showDetail, setShowDetail] = useState(false);

    const handleCookiePreferences = () => {
        let accept = { necessary: true };

        if (presentationCookies) {
            accept = { ...accept, preferences: true };
        }

        if (targetingCookies) {
            accept = { ...accept, marketing: true, statistics: true };
        }

        acceptCookies(accept);
    };

    const toggleChoices = () => setShowDetail(!showDetail);

    if (Object.keys(consent).length <= 1) {
        return (
            <section className="apolloCookies">
                <div className="container">
                    <div className="row">
                        {showDetail === false ? (
                            <div className="col col--6">
                                <div className="cookies">
                                    <h2>Wij maken gebruik van cookies!</h2>
                                    <p>
                                        Hallo, deze website gebruikt essentiële cookies om de goede werking van de
                                        website te garanderen en tracking cookies om te begrijpen hoe u de website
                                        gebruikt. De tracking cookies worden pas na toestemming aangezet.
                                    </p>
                                    <div className="options">
                                        <button onClick={acceptAllCookies}>Alle accepteren</button>
                                        <span
                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                            onClick={toggleChoices}
                                        >
                                            Keuzes aanpassen
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col col--6">
                                <div className="cookies">
                                    <h2>Cookie voorkeuren</h2>
                                    <p>
                                        Wij gebruiken cookies om de basisfunctionaliteiten van de website te waarborgen
                                        en om uw online ervaring te verbeteren. U kunt voor elke categorie kiezen om u
                                        aan/uit te melden wanneer u maar wilt. Voor meer details met betrekking tot
                                        cookies en andere gevoelige gegevens, lees de volledige privacy policy.
                                    </p>

                                    <hr />

                                    <div>
                                        <Toggle defaultChecked={false} disabled={true} />
                                        <label>Noodzakelijke cookies</label>
                                    </div>
                                    <div>
                                        <Toggle
                                            id="presentationOption"
                                            defaultChecked={false}
                                            onChange={setPresentationCookies}
                                        />
                                        <label htmlFor="presentationOption">Presentatie- en analysecookies</label>
                                    </div>
                                    <div>
                                        <Toggle
                                            id="targetingOption"
                                            defaultChecked={false}
                                            onChange={setTargetingCookies}
                                        />
                                        <label htmlFor="targetingOption">Advertentie- en targetingcookies</label>
                                    </div>

                                    <h3>Meer informatie</h3>
                                    <p>
                                        Voor vragen met betrekking tot ons beleid inzake cookies en uw keuzes, gelieve
                                        contact op te nemen.
                                    </p>

                                    <div className="options">
                                        <div>
                                            <button onClick={acceptAllCookies}>Alle accepteren</button>
                                            <button style={{ marginLeft: '5px' }} onClick={declineAllCookies}>
                                                Alle weigeren
                                            </button>
                                        </div>
                                        <button onClick={handleCookiePreferences}>Keuzes opslaan</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        );
    }

    return null;
}
