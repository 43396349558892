import { useLayoutEffect, useState } from 'react';

export default function usePageSize() {
    const [size, setSize] = useState({ x: 0, y: 0 });

    useLayoutEffect(() => {
        const { body } = document;
        const html = document.documentElement;

        function updateSize() {
            const height = Math.max(
                body.scrollHeight,
                body.offsetHeight,
                html.clientHeight,
                html.scrollHeight,
                html.offsetHeight,
            );

            const width = Math.max(
                body.scrollWidth,
                body.offsetWidth,
                html.clientWidth,
                html.scrollWidth,
                html.offsetWidth,
            );

            setSize({ x: width, y: height });
        }

        document.addEventListener('resize', updateSize);

        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
}
