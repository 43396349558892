import axios from 'axios';
import { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import ApolloMarkdown from '../../framework/components/ApolloMarkdown';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import ContactLocation from './ContactLocation';
import Loader from './Loader';

import './scss/contactSection.scss';

export default function ContactSection({ page }) {
    const [isInView, setIsInView] = useState(false);
    const [locations, setLocations] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const apiUrl = useApiUrl();

    const [image, setImage] = useState(null);

    useEffect(() => {
        if (page !== null) {
            setImage(page.images.split(',')[0]);
        }
    }, [page]);

    useEffect(() => {
        async function getLocations() {
            if (apiUrl === null) return;

            await axios
                .get(`${apiUrl}/locations`)
                .then((response) => {
                    setLocations(response.data);
                    setIsLoaded(true);
                })
                .catch(() => {
                    setIsLoaded(false);
                });
        }

        if (locations === null) getLocations();
    }, [locations, apiUrl]);

    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    if (isLoaded) {
        return (
            <section className="contact">
                <Waypoint bottomOffset="50%" onEnter={handleInView} onLeave={handleOutOfView}>
                    <div className="container">
                        <div className="row">
                            <div className="col col--6">
                                <div className={`title ${isInView ? 'active' : ''}`}>
                                    <h1>
                                        <ApolloMarkdown inline>{page?.paginatitel ?? page?.titel}</ApolloMarkdown>
                                    </h1>
                                </div>
                                <ApolloMarkdown inline={false}>{page?.content}</ApolloMarkdown>
                                {locations.map((value, key) => (
                                    <ContactLocation key={key} value={value} />
                                ))}
                            </div>
                            <div className="col col--6">
                                <div className="image">
                                    <img src={`${apiUrl}/img/ratio/website/775/500/100/${image}`} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Waypoint>
            </section>
        );
    }
    return <Loader />;
}
