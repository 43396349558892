import { lazy, Suspense } from 'react';
import Loader from '../components/blocks/Loader';
import Navigation from '../components/Navigation';
import ProductsHome from '../components/products/ProductsHome';
import usePage from '../framework/hooks/usePageHook';

const LatestNews = lazy(() => import('../components/news/LatestNews'));
const USPSection = lazy(() => import('../components/blocks/USPSection'));
const Footer = lazy(() => import('../components/Footer'));

export default function ProductsPage({ page }) {
    return (
        <>
            <Navigation />
            <ProductsHome />
            <Suspense fallback={<Loader />}>
                <LatestNews />
                <USPSection />
                <Footer />
            </Suspense>
        </>
    );
}
