import path from 'path-browserify';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import ApolloMarkdown from '../../framework/components/ApolloMarkdown';
import ApolloParallax from '../../framework/components/ApolloParallax';
import ApolloPrerender from '../../framework/components/ApolloPrerender';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import useMediaSize from '../../framework/hooks/useMediaSize';
import ImageReveal from '../interaction/ImageReveal';

import './scss/headerHome.scss';

export default function HeaderHome({ page }) {
    const [isInView, setIsInView] = useState(false);
    const [isDesktop, setIsDesktop] = useState(true);
    const [images, setImages] = useState(null);

    const apiUrl = useApiUrl();
    const size = useMediaSize();

    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    useEffect(() => {
        setIsDesktop(size.width >= size.breakpoint.xl);
    }, [size]);

    useEffect(() => {
        if (page && page.images) {
            setImages(page.images.split(','));
        }
    }, [page]);

    return (
        <header className="headerHome">
            <Waypoint topOffset="50%" onEnter={handleInView} onLeave={handleOutOfView}>
                <div className="container container--gap-large">
                    <div className="row">
                        <div className="col col--6 textCol">
                            <div className={`title ${isInView ? 'active' : ''}`}>
                                <h1>
                                    <ApolloPrerender lines={2} fontSize="1em" lineHeight=".5em">
                                        <ApolloMarkdown inline>{page?.paginatitel ?? page?.titel}</ApolloMarkdown>
                                    </ApolloPrerender>
                                </h1>
                            </div>
                            <div className="content">
                                <ApolloPrerender
                                    lines={6}
                                    fontSize={isDesktop ? '1.85rem' : '1.25rem'}
                                    lineHeight=".75em"
                                >
                                    <ApolloMarkdown inline={false}>{page?.inhoud}</ApolloMarkdown>
                                </ApolloPrerender>
                            </div>
                            <Link to="/producten" className="btn">
                                Bekijk het assortiment
                            </Link>
                        </div>
                        <div className="col col--6 images">
                            <div className="imageWrap">
                                <ApolloParallax
                                    className="image image--primary"
                                    speed={0.2}
                                    offset="100px"
                                    enabled={isDesktop}
                                >
                                    <ImageReveal
                                        backgroundColor="var(--color-main)"
                                        src={
                                            images !== null
                                                ? `${apiUrl}/img/exact/website/372/500/100/${images[0]}`
                                                : false
                                        }
                                        delay="0s"
                                        speed=".75s"
                                        aspectRatio="400 / 538"
                                    >
                                        <div className="image-decoration--dash-top image-decoration--dash-white" />
                                        <div className="image-decoration--dash-bottom image-decoration--dash-blue" />
                                    </ImageReveal>
                                </ApolloParallax>
                                <ApolloParallax
                                    className="image image--secondary"
                                    speed={0.1}
                                    offset="75px"
                                    enabled={isDesktop}
                                >
                                    <ImageReveal
                                        backgroundColor="var(--color-main)"
                                        src={
                                            images !== null
                                                ? `${apiUrl}/img/exact/website/372/735/100/${images[1]}`
                                                : false
                                        }
                                        delay="0.5s"
                                        speed="1s"
                                        aspectRatio="400 / 790"
                                    >
                                        <div className="image-decoration--dash-left image-decoration--dash-blue" />
                                        <div className="image-decoration--dash-right image-decoration--dash-white" />
                                    </ImageReveal>
                                </ApolloParallax>
                            </div>
                        </div>
                    </div>
                </div>
            </Waypoint>
        </header>
    );
}
