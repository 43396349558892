import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Waypoint } from 'react-waypoint';
import ApolloMarkdown from '../../framework/components/ApolloMarkdown';
import ApolloParallax from '../../framework/components/ApolloParallax';
import ApolloPrerender from '../../framework/components/ApolloPrerender';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import useMediaSize from '../../framework/hooks/useMediaSize';

import './scss/productDetail.scss';

export default function ProductDetail({ id }) {
    const [isInView, setIsInView] = useState(false);
    const [product, setProduct] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isDesktop, setIsDesktop] = useState(true);

    const size = useMediaSize();
    const apiUrl = useApiUrl();

    const parallaxConstraint = useRef();

    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    useEffect(() => {
        async function getProduct() {
            if (apiUrl === null) return;

            await axios
                .get(`${apiUrl}/products/${id}`)
                .then((response) => {
                    setProduct(response.data);
                    setIsLoaded(true);
                })
                .catch(() => {
                    setIsLoaded(false);
                });
        }

        if (product === null) getProduct();
    }, [product, apiUrl]);

    useEffect(() => {
        setIsDesktop(size.width >= size.breakpoint.xl);
    }, [size]);

    return (
        <>
            <Helmet>
                <title>
                    {product?.title.replace(/[^a-z0-9 ]/gi, '') ?? ''} - {process.env.MIX_REACT_APP_APP_NAME}
                </title>
                <meta
                    name="name"
                    content={`${product?.title.replace(/[^a-z0-9 ]/gi, '') ?? ''} - ${process.env.MIX_REACT_APP_APP_NAME}`}
                />
            </Helmet>
            <section className="productDetail" ref={parallaxConstraint}>
                <Waypoint bottomOffset="50%" onEnter={handleInView} onLeave={handleOutOfView}>
                    <div className="container container--gap-large">
                        <div className="row">
                            <div className="col col--7 content">
                                <div className={`title ${isInView ? 'active' : ''}`}>
                                    <h1>
                                        <ApolloPrerender lines={2}>
                                            <ApolloMarkdown inline>{product?.title}</ApolloMarkdown>
                                        </ApolloPrerender>
                                    </h1>
                                </div>
                                <ApolloPrerender
                                    lines={7}
                                    fontSize={isDesktop ? '1.6rem' : '1.25rem'}
                                    lineHeight="1rem"
                                >
                                    <ApolloMarkdown inline={false}>{product?.content}</ApolloMarkdown>
                                </ApolloPrerender>
                            </div>
                            <div className="col col--5 images">
                                <div className="imageWrap">
                                    {apiUrl !== null && product !== null
                                        ? product.images.slice(0, 3).map((image, index) => (
                                              <ApolloParallax
                                                  key={index}
                                                  className="image"
                                                  offset="0px"
                                                  speed={0.15 * index}
                                                  constraint={parallaxConstraint}
                                                  enabled={isDesktop}
                                              >
                                                  <img
                                                      src={`${apiUrl}/img/ratio/product_category/775/500/100/${image}`}
                                                      alt=""
                                                  />
                                              </ApolloParallax>
                                          ))
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </Waypoint>
            </section>
            <section className="expertiseHome">
                <div className="container container--gap-large">
                    <div className="row">
                        <div className="col col--4 col-lg--12">
                            <h2>Hoe te gebruiken?</h2>
                        </div>
                        <div className="col col--8 col-lg--12 text">
                            <ApolloPrerender lines={3}>
                                <ApolloMarkdown inline={false}>{product?.usage}</ApolloMarkdown>
                            </ApolloPrerender>
                            {/* <Button to={section?.link_url ?? ''}>{section?.link_text}</Button> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
