import { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import './scss/imageReveal.scss';

export default function ImageReveal({
    src,
    children = null,
    className = '',
    offsetDirection = 'top',
    offset = '50%',
    speed = '.45s',
    delay = '1s',
    aspectRatio = '4 / 3',
    backgroundColor = 'var(--color-light)',
}) {
    const [isInView, setIsInView] = useState(false);

    const handleInView = () => setIsInView(true);

    const image = (
        <div className="image" style={{ aspectRatio, backgroundColor }}>
            <img
                src={src}
                alt=""
                style={{
                    transform: `translateX(${isInView ? 0 : -100}%)`,
                    transitionDuration: speed,
                    transitionDelay: delay,
                }}
            />
        </div>
    );

    return (
        <div className={`imageReveal ${className}`}>
            {children ?? null}
            {offsetDirection === 'top' ? (
                <Waypoint topOffset={offset} onEnter={handleInView}>
                    {src ? image : <div>Loading</div>}
                </Waypoint>
            ) : (
                <Waypoint bottomOffset={offset} onEnter={handleInView}>
                    {src ? image : <div>Loading</div>}
                </Waypoint>
            )}
        </div>
    );
}
