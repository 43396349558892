import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import ApolloMarkdown from '../../framework/components/ApolloMarkdown';
import ApolloParallax from '../../framework/components/ApolloParallax';
import ApolloPrerender from '../../framework/components/ApolloPrerender';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import useMediaSize from '../../framework/hooks/useMediaSize';

import './scss/newsDetail.scss';

export default function NewsDetail({ id }) {
    const [isInView, setIsInView] = useState(false);
    const [newsItem, setNewsItem] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState(null);
    const [isDesktop, setIsDesktop] = useState(true);

    const size = useMediaSize();
    const apiUrl = useApiUrl();

    const parallaxConstraint = useRef();

    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    useEffect(() => {
        const controller = new AbortController();

        async function getNews() {
            if (apiUrl === null) return;

            await axios
                .get(`${apiUrl}/news/${id}`, {
                    signal: controller.signal,
                })
                .then((response) => {
                    setNewsItem(response.data);
                })
                .catch(() => {
                    console.error('Could not fetch data from TeamHome 01');
                });
        }

        if (newsItem === null) getNews();

        return () => {
            controller.abort();
        };
    }, [newsItem, apiUrl]);

    useEffect(() => {
        const controller = new AbortController();

        async function getRelatedProducts() {
            if (apiUrl === null) return;

            await axios
                .get(`${apiUrl}/news/${id}/products`, {
                    signal: controller.signal,
                })
                .then((response) => {
                    setRelatedProducts(response.data);
                })
                .catch((err) => {
                    if (err.name === 'AbortError' || err.name === 'CanceledError') {
                        console.log('successfully aborted');
                    }
                });
        }

        if (relatedProducts === null) getRelatedProducts();

        return () => {
            controller.abort();
        };
    }, [relatedProducts, apiUrl]);

    useEffect(() => {
        setIsDesktop(size.width >= size.breakpoint.xl);
    }, [size]);

    return (
        <section className="newsDetail" ref={parallaxConstraint}>
            <div className="container">
                <div className="row">
                    <Waypoint topOffset="50%" onEnter={handleInView} onLeave={handleOutOfView}>
                        <div className="col col--7">
                            <div className={`title ${isInView ? 'active' : ''}`}>
                                <h1>
                                    <ApolloPrerender lines={2}>
                                        <ApolloMarkdown inline>{newsItem?.title}</ApolloMarkdown>
                                    </ApolloPrerender>
                                </h1>
                            </div>
                            <ApolloPrerender lines={8} fontSize="1.6rem" lineHeight="1rem">
                                <ApolloMarkdown inline={false}>{newsItem?.content}</ApolloMarkdown>
                            </ApolloPrerender>

                            {relatedProducts && relatedProducts.length > 0 ? (
                                <div className="related">
                                    <h2>Gerelateerde producten</h2>
                                    <ul>
                                        {relatedProducts.map((item, key) => {
                                            return (
                                                <li key={key}>
                                                    <Link
                                                        to={`/producten/${item.id}/${
                                                            item.url_titel ??
                                                            encodeURIComponent(
                                                                item.title
                                                                    .toLowerCase()
                                                                    .replace(/ /g, '-')
                                                                    .replace(/\*/g, ''),
                                                            )
                                                        }`}
                                                    >
                                                        <ApolloMarkdown inline>{item.title}</ApolloMarkdown>
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            ) : null}
                        </div>
                    </Waypoint>
                    <div className="col col--5">
                        <div className="imageWrap">
                            {apiUrl !== null && newsItem !== null && newsItem.images
                                ? newsItem.images.slice(0, 3).map((image, index) => (
                                    <ApolloParallax
                                        key={index}
                                        className="image"
                                        offset="0px"
                                        speed={0.15 * index}
                                        constraint={parallaxConstraint}
                                        enabled={isDesktop}
                                    >
                                        <img src={`${apiUrl}/img/ratio/news/775/500/100/${image}`} alt="" />
                                    </ApolloParallax>
                                ))
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
