import { lazy, Suspense } from 'react';
import Navigation from '../components/Navigation';
import NewsDetail from '../components/news/NewsDetail';
import usePage from '../framework/hooks/usePageHook';

const TeamHome = lazy(() => import('../components/blocks/TeamHome'));
const LatestNews = lazy(() => import('../components/news/LatestNews'));
const USPSection = lazy(() => import('../components/blocks/USPSection'));
const Footer = lazy(() => import('../components/Footer'));

export default function NewsDetailPage({ id, page }) {
    return (
        <>
            <Navigation />
            <NewsDetail id={id} />
            <Suspense fallback={<h1>loading</h1>}>
                <TeamHome />
                <LatestNews />
                <USPSection />
                <Footer />
            </Suspense>
        </>
    );
}
