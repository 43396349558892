import axios from 'axios';
import { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import ApolloMarkdown from '../../framework/components/ApolloMarkdown';
import ApolloParallax from '../../framework/components/ApolloParallax';
import useApiUrl from '../../framework/hooks/useApiUrlHook';
import NewsOverviewCard from './NewsOverviewCard';
import './scss/newsOverview.scss';

export default function NewsOverview() {
    const [isInView, setIsInView] = useState(false);
    const [news, setNews] = useState(null);

    const apiUrl = useApiUrl();

    const handleInView = () => setIsInView(true);
    const handleOutOfView = () => setIsInView(false);

    useEffect(() => {
        const controller = new AbortController();

        async function getNews() {
            if (apiUrl === null) return;

            await axios
                .get(`${apiUrl}/news/overview`, {
                    signal: controller.signal,
                })
                .then((response) => {
                    setNews(response.data);
                })
                .catch((err) => {
                    if (err.name === 'AbortError' || err.name === 'CanceledError') {
                        console.log('successfully aborted');
                    }
                    console.error("Couldn't retrieve nieuws");
                });
        }

        if (news === null) getNews();

        return () => {
            controller.abort();
        };
    }, [news, apiUrl]);

    return (
        <section className="newsOverview">
            <Waypoint topOffset="50%" onEnter={handleInView} onLeave={handleOutOfView}>
                <div className="container">
                    <div className="row">
                        <ApolloParallax className="col col--6" speed={0.05} offset="0px">
                            <div className="row">
                                <div className="col col--12">
                                    <div className="newsOverviewCard newsOverviewCard--heading">
                                        <div className={`title ${isInView ? 'active' : ''}`}>
                                            <h1>
                                                <ApolloMarkdown inline>
                                                    Blijf op de hoogte van het **laatste nieuws**
                                                </ApolloMarkdown>
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                                {news !== null
                                    ? news
                                          .map((item, index) => {
                                              const mod = index % 10;
                                              if (mod === 1) {
                                                  return <NewsOverviewCard key={index} size="large" item={item} />;
                                              }
                                              if (mod >= 6 && mod <= 9) {
                                                  return <NewsOverviewCard key={index} size="small" item={item} />;
                                              }
                                              return null;
                                          })
                                          .filter((i) => i !== null)
                                    : null}
                            </div>
                        </ApolloParallax>
                        <ApolloParallax className="col col--6" speed={0.1} offset="100px">
                            <div className="row">
                                {news !== null
                                    ? news
                                          .map((item, index) => {
                                              const mod = index % 10;
                                              if (mod === 0) {
                                                  return <NewsOverviewCard key={index} size="large" item={item} />;
                                              }
                                              if (mod >= 2 && mod <= 5) {
                                                  return <NewsOverviewCard key={index} size="small" item={item} />;
                                              }
                                              return null;
                                          })
                                          .filter((i) => i !== null)
                                    : null}
                            </div>
                        </ApolloParallax>
                    </div>
                </div>
            </Waypoint>
        </section>
    );
}
